
// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
// const _import = require('./import-' + process.env.NODE_ENV)
const _import = require('./import-production')

export default[
  {
      path: '/example1',
      component: _import('main'),
      name: 'example1',
      redirect: { name: 'home1' },
      meta: { title: '主入口整体布局' , isTab: false, requireAuth: true},
      children: [
        // 通过meta对象设置路由展示方式
        // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
        // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
        // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
        { path: 'home', component: _import('common/home'), name: 'home1', meta: { title: '首页', isTab: false } },
        {path: 'page1', component: _import('example_module1/module1/page1'), name: 'example1Page1', meta: {title: '页面1', isTab: true}},
        {
          path: 'page2', component: _import('example_module1/module1/page2'), name: 'example1Page2', meta: {title: '页面2', isTab: true},redirect: { name: 'example1Page3' },
          children:[{path: 'page3', component: _import('example_module1/module2/page3'), name: 'example1Page3', meta: {title: '页面3', isTab: false}}]
        }
        // { path: '/theme', component: _import('common/theme'), name: 'theme', meta: { title: '主题' } },
      ],
  }
]