
// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
// const _import = require('./import-' + process.env.NODE_ENV)
const _import = require('./import-production')

export default[
    {
        path: '/home_older',
        component: _import('main'),
        name: 'home_older',
        redirect: { name: 'home_older_home' },
        meta: { title: '主入口整体布局',isTab: false },
        children: [
            // 通过meta对象设置路由展示方式
            // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
            // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
            // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
            { path: 'home', component: _import('common/home'), name: 'home_older_home', meta: { title: '首页',isTab: false } },
            {path: 'olderMember', component: _import('home_older/stationData/olderMember'), name: 'olderMember', meta: {title: '成员管理', isTab: true}},
            {
                path: 'examination', component: _import('home_older/stationData/examination'), name: 'examination', meta: {title: '体检情况', isTab: true},
            },
            // { path: 'healthPolularization', component: _import('older_station/popularization/video'), name: 'healthPolularization', meta: { title: '健康科普',isTab: true } },
        //     {path: 'page5', component: _import('older_station/accountSetting/page5'), name: 'accountPage5', meta: {title: '账户信息', isTab: true}},
        //    {path: 'page6', component: _import('older_station/accountSetting/page6'), name: 'accountPage6', meta: {title: '修改密码', isTab: true}},
        ],
    }
]
