
// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
// const _import = require('./import-' + process.env.NODE_ENV)
const _import = require('./import-production')

export default[
    {
        path: '/station',
        component: _import('main'),
        name: 'station',
        redirect: { name: 'station_home' },
        meta: { title: '主入口整体布局',isTab: false },
        children: [
            // 通过meta对象设置路由展示方式
            // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
            // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
            // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
            { path: 'home', component: _import('common/home'), name: 'station_home', meta: { title: '首页',isTab: false } },
            {path: 'memberData', component: _import('health_station/stationData/memberData'), name: 'memberData_station', meta: {title: '成员管理', isTab: true}},
            {path: 'relaCheck', component: _import('health_station/stationData/relaCheck'), name: 'relaCheck', meta: {title: '小屋体检情况', isTab: true},},
            {path: 'page3', component: _import('health_station/remoteTreatment/page3'), name: 'remotePage3', meta: {title: '远程诊疗预约', isTab: true}},
            {path: 'page4', component: _import('health_station/stationIntroduction/page4'), name: 'introductionPage4', meta: {title: '小屋介绍', isTab: true}/*,redirect: { name: 'introductionPage4_1' },
                children:[{path: 'page4_1', component: _import('health_station/stationIntroduction/page4_1'), name: 'introductionPage4_1', meta: {title: '小屋介绍子一',isTab:false}}]*/},
            {path: 'page5', component: _import('health_station/accountSetting/page5'), name: 'accountPage5', meta: {title: '账户信息', isTab: true}},
           {path: 'page6', component: _import('health_station/accountSetting/page6'), name: 'accountPage6', meta: {title: '修改密码', isTab: true}},
            // {path: 'page7', component: _import('health_station/notification/page7'), name: 'noticePage7', meta: {title: '消息通知', isTab: true}},
            {path: 'page7', component: _import('health_station/notification/page7'), name: 'noticePage7', meta: {title: '消息通知', isTab: true}},
            {path: 'healthDetailPage1', component: _import('health_station/healthDetailPage1'), name: 'healthDetailPage1', meta: {title: '健康信息:心电监测', isTab: true}},
            {path: 'privateMessage1', component: _import('health_station/privateMessage1'), name: 'privateMessage1', meta: {title: '个人消息', isTab: true}},
            {path: 'systemMessage1', component: _import('health_station/systemMessage1'), name: 'systemMessage1', meta: {title: '系统通知', isTab: true}},
            {path: 'writeMessage1', component: _import('health_station/writeMessage1'), name: 'writeMessage1', meta: {title: '发送消息', isTab: true}},
            {path: 'remoteHistory1', component: _import('health_station/remoteTreatment/remoteHistory'), name: 'remoteHistory1', meta: {title: '预约就诊历史', isTab: true}},
            {path: 'emergency1', component: _import('health_station/remoteTreatment/emergency_treatment'), name: 'emergency1', meta: {title: '急诊', isTab: true}},
            // { path: '/theme', component: _import('common/theme'), name: 'theme', meta: { title: '主题' } },stationIntroduction
        ],
    }
]
