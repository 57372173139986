
// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
// const _import = require('./import-' + process.env.NODE_ENV)

const _import = require('./import-production')

export default [
    {
        path: '/XingouDoctor',
        component: _import('main'),
        name: 'XingouDoctor',
        redirect: { name: 'home' },
        meta: { title: '主入口整体布局', isTab: false },
        children: [
            // 通过meta对象设置路由展示方式
            // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
            // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
            // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
            { path: 'home', component: _import('common/home'), name: 'home', meta: { title: '首页', isTab: false } },
            { path: 'checkList', component: _import('doctor_station/consultant/checkList'), name: 'checkList', meta: { title: '咨询列表', isTab: true } },
            { path: 'checkContent', component: _import('doctor_station/consultant/checkContent'), name: 'checkContent', meta: { title: '心电复核', isTab: true } },

            { path: 'remotePage1', component: _import('doctor_station/remoteTreatment/appointmentList'), name: 'remotePage1', meta: { title: '预约列表', isTab: true } },
            { path: 'remotePage2', component: _import('doctor_station/remoteTreatment/treatmentHistory'), name: 'remotePage2', meta: { title: '出诊历史', isTab: true }, },
            { path: 'remoteDocPage3', component: _import('doctor_station/remoteTreatment/timeSelect'), name: 'remoteDocPage3', meta: { title: '开放预约', isTab: true } },
            { path: 'home', component: _import('common/home'), name: 'home', meta: { title: '首页',isTab: false } },
            {path: 'checkList', component: _import('doctor_station/consultant/checkList'), name: 'checkList', meta: {title: '咨询列表', isTab: true}},
            {path: 'checkContent', component: _import('doctor_station/consultant/checkContent'), name: 'checkContent', meta: {title: '心电复核', isTab: true}},

            {path: 'remotePage1', component: _import('doctor_station/remoteTreatment/appointmentList'), name: 'remotePage1', meta: {title: '预约列表', isTab: true}},
            {path: 'remotePage2', component: _import('doctor_station/remoteTreatment/treatmentHistory'), name: 'remotePage2', meta: {title: '出诊历史', isTab: true},},
            {path: 'remoteDocPage3', component: _import('doctor_station/remoteTreatment/timeSelect'), name: 'remoteDocPage3', meta: {title: '开放预约', isTab: true}},
            {path: 'emergency2', component: _import('doctor_station/remoteTreatment/emergency_treatment2'), name: 'emergency2', meta: {title: '开放预约', isTab: true}},

            {path: 'memberData', component: _import('doctor_station/vip/memberData'), name: 'memberData', meta: {title: '成员数据', isTab: true}},
            {path: 'MonthlyReport', component: _import('doctor_station/vip/MonthlyReport'), name: 'MonthlyReport', meta: {title: '每月报告', isTab: true}},

            {path: 'page4', component: _import('doctor_station/report/SingoReport1'), name: 'SingoReport1', meta: {title: '静态单导', isTab: true}},
            {path: 'TreatReport', component: _import('doctor_station/remoteTreatment/TreatReport'), name: 'TreatReport', meta: {title: '诊疗报告', isTab: true}},

            {path: 'docTreat1', component: _import('doctor_station/On_Treat/Doc_Treat'), name: 'docTreat1', meta: {title: '远程诊室', isTab: true},
            children: [{path: 'across',
                component: _import('common/across_template'),
                children: [
                    // {path: 'PsInfoJump11', component: _import('personal_module/ps_info/ps_information'), name: 'PsInfoJump11', meta: {title: '个人信息表', isTab: false}},
                    // {path: 'ExamReportJump11', component: _import('personal_module/records/report'), name: 'ExamReportJump11', meta: {title: '综合体检报告', isTab: false}},
                    {
                        path: 'HistorySingoTreat', component: _import('personal_module/records/History/HistorySingo'), name: 'HistorySingoTreat', meta: {title: '单导联心电检测记录', isTab: false, showfater: true},
                        children:[{path: 'HistorySingo2Treat', component: _import('personal_module/records/History/HistorySingo2'), name: 'HistorySingo2Treat', meta: {title: '页面', isTab: false, showfater: false}},
                        ]
                    },
                    {
                        path: 'HistorySingoHolterTreat', component: _import('personal_module/records/History/HistorySingoHolter'), name: 'HistorySingoHolterTreat', meta: {title: '单导联Holter检测记录', isTab: false, showfater: true},
                        children:[{path: 'HistorySingoHolter2Treat', component: _import('personal_module/records/History/HistorySingoHolter2'), name: 'HistorySingoHolter2Treat', meta: {title: '页面', isTab: false, showfater: false}},
                            {path: 'holterReportTreat', component: _import('personal_module/records/holter_report'), name: 'holterReportTreat', meta: {title: '动态报告', isTab: false, showfater: false}}]
                    },
                    {
                        path: 'HistoryPhotoTreat', component: _import('personal_module/records/report'), name: 'HistoryPhotoTreat', meta: {title: '综合体检报告', isTab: false, showfater: true},
                    },
                ]
            }],},
            { path: 'memberData', component: _import('doctor_station/vip/memberData'), name: 'memberData_doctor', meta: { title: '成员管理', isTab: true } },
            { path: 'vipMemberData', component: _import('doctor_station/vip/vipMemberData'), name: 'vipMemberData', meta: { title: 'VIP管理', isTab: true } },
            { path: 'MonthlyReport', component: _import('doctor_station/vip/MonthlyReport'), name: 'MonthlyReport', meta: { title: '报告录入', isTab: true } },
            { path: 'ViewMonthlyReport', component: _import('doctor_station/vip/ViewMonthlyReport'), name: 'ViewMonthlyReport', meta: { title: '每月报告', isTab: true } },
            { path: 'vipcheckContent', component: _import('doctor_station/vip/vipcheckContent'), name: 'vipcheckContent', meta: { title: 'vip复核', isTab: true } },

            { path: 'page4', component: _import('doctor_station/report/SingoReport1'), name: 'SingoReport1', meta: { title: '静态单导', isTab: true } },

            { path: 'editPsInfo', component: _import('doctor_station/editPsInfo/editPsInfo'), name: 'editPsInfo', meta: { title: '修改信息', isTab: true } },

            // { path: '/theme', component: _import('common/theme'), name: 'theme', meta: { title: '主题' } },
        ],
    }
]