export default {
    namespaced: true,
    state: {
        // 这里写变量名
        // moduleName: '',
    },
    mutations: {
        // 这里写变量更新方法，仿照写
        // updateModuleName (state, moduleName) {
        //     state.moduleName = moduleName
        // },
    }
}
