import {storeMaker} from '../utils/storeMaker.js'

export default {
    namespaced: true,
    state: storeMaker({
        // 这里写变量名
        // moduleName: '',
        eid:'',
        ename:'',
        last_login:'',

    }),
    mutations: {
        // 这里写变量更新方法，仿照写
        // updateModuleName (state, moduleName) {
        //     state.moduleName = moduleName
        // },
        updateeId (state, eid) {
            state.eid = eid
        },
        updateeName (state, ename) {
            state.ename = ename
        },
        updateLastLogin(state, last_login) {
            state.last_login = last_login
        }, 
    }
}