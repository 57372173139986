import {storeMaker} from '../utils/storeMaker.js'

export default {
    namespaced: true,
    state: storeMaker({
        // 这里写变量名
        // moduleName: '',
        uid: '',
        username: '',
        staticRecordId: '',
        last_login:''
    }),
    mutations: {
        // 这里写变量更新方法，仿照写
        updateUId (state, uid) {
            state.uid = uid
        }, 
        updateUsername (state, username) {
            state.username = username
        }, 
        updateStaticRecordId(state, staticRecordId) {
            state.staticRecordId = staticRecordId
        }, 
        updateLastLogin(state, last_login) {
            state.last_login = last_login
        }, 
    }
  }