import Vue from 'vue'
import Vuex from 'vuex'
// import cloneDeep from 'lodash/cloneDeep'
import common from './public/common'
import module from './public/module'
// import example_module from './example_module'
import example_module1 from './example_module1'
import health_station from './health_station'
import personal_module from './personal_module'
import community_station from './community_station'
import doctor_station from './doctor_station';
import women_baby from './women_baby'
import home_older from './home_older'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common,
    module,
    // example_module,
    example_module1,
    health_station,
    personal_module,
    community_station,
    doctor_station,
    women_baby,
    home_older
  },
  mutations: {
    // 重置vuex本地储存状态
    resetStore (state) {
      Object.keys(state).forEach((key) => {
        // state[key] = cloneDeep(window.SITE_CONFIG['storeState'][key])
      })
    }
  },
  strict: process.env.NODE_ENV !== 'production'
})
