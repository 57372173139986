import {storeMaker} from '../utils/storeMaker.js'

export default {
    namespaced: true,
    state: storeMaker({
        // 这里写变量名
        // moduleName: '',
        eid:'',
        ename:'',
        last_login:'',
        selfCareScore: 0,
        basicMobilityScore: 0,
        mentalStateScore: 0,
        sensorySocialScore: 0,
        coma: false,
        dementia: false,
        riskEvents: false,
        reportStatus: 0, // 0：是平台修改； 1： 查看
    }),
    mutations: {
        // 这里写变量更新方法，仿照写
        // updateModuleName (state, moduleName) {
        //     state.moduleName = moduleName
        // },
        updateeId (state, eid) {
            state.eid = eid
        },
        updateeName (state, ename) {
            state.ename = ename
        },
        updateLastLogin(state, last_login) {
            state.last_login = last_login
        }, 
        updateSelfCareScore(state, selfCareScore) {
            state.selfCareScore = selfCareScore
        }, 
        updateBasicMobilityScore(state, basicMobilityScore) {
            state.basicMobilityScore = basicMobilityScore
        }, 
        updateMentalStateScore(state, mentalStateScore) {
            state.mentalStateScore = mentalStateScore
        }, 
        updateSensorySocialScore(state, sensorySocialScore) {
            state.sensorySocialScore = sensorySocialScore
        }, 
        updateComa(state, coma) {
            state.coma = coma
        }, 
        updateDementia(state, dementia) {
            state.dementia = dementia
        }, 
        updateRiskEvents(state, riskEvents) {
            state.riskEvents = riskEvents
        }, 
        updateReportStatus(state, reportStatus) {
            state.reportStatus = reportStatus
        }, 

    }
}