const _import = require('./import-production')

export default [
  {
    path: '/myhealth',
    component: _import('main'),
    name: 'myhealth',
    redirect: { name: 'myhealthHome' },
    meta: { title: '主入口整体布局', isTab: false },
    children: [
      // 通过meta对象设置路由展示方式
      // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
      // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
      // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
      {
        path: 'home', component: _import('common/home'), name: 'myhealthHome', meta: { title: '首页', isTab: false }, redirect: { name: 'HealthReportWeekly' },
        children: [{ path: 'HealthReportWeekly', component: _import('personal_module/HealthReportWeekly'), name: 'HealthReportWeekly', meta: { title: '健康周报', isTab: false } },
        ]
      },

      { path: 'myh_history', component: _import('personal_module/records/myh_history'), name: 'myh_history', meta: { title: '检测项目/记录', isTab: true } },
      { path: 'myh_tend', component: _import('personal_module/records/myh_tend'), name: 'myh_tend', meta: { title: '总体趋势分析', isTab: true } },
      {
        // path: 'HistoryTab', component: _import('personal_module/records/History/HistoryTab'), name: 'HistoryTab', meta: {title: '历史记录', isTab: true},
        // children:[{path: 'test', component: _import('personal_module/records/History/test'), name: 'test', meta: {title: '页面3', isTab: false}}]
        path: 'HistorySingo', component: _import('personal_module/records/History/HistorySingo'), name: 'HistorySingo', meta: { title: '单导联心电检测记录', isTab: true, showfater: true },
        children: [{ path: 'HistorySingo2', component: _import('personal_module/records/History/HistorySingo2'), name: 'HistorySingo2', meta: { title: '页面', isTab: false, showfater: false } },
        ]
      },
      {
        path: 'HistorySingoHolter', component: _import('personal_module/records/History/HistorySingoHolter'), name: 'HistorySingoHolter', meta: { title: '单导联Holter检测记录', isTab: true, showfater: true },
        children: [{ path: 'HistorySingoHolter2', component: _import('personal_module/records/History/HistorySingoHolter2'), name: 'HistorySingoHolter2', meta: { title: '页面', isTab: false, showfater: false } },
        { path: 'holterReport', component: _import('personal_module/records/holter_report'), name: 'holterReport', meta: { title: '动态报告', isTab: false, showfater: false } }]
      },
      {
        path: 'HistoryEight', component: _import('personal_module/records/History/HistoryEight'), name: 'HistoryEight', meta: { title: '八导联心电检测记录', isTab: true, showfater: true },
        children: [{ path: 'HistoryEight2', component: _import('personal_module/records/History/HistoryEight2'), name: 'HistoryEight2', meta: { title: '页面', isTab: false, showfater: false } }]
      },
      {
        path: 'HistoryEightHolter', component: _import('personal_module/records/History/HistoryEightHolter'), name: 'HistoryEightHolter', meta: { title: '八导联Holter检测记录', isTab: true, showfater: true },
        children: [{ path: 'HistoryEightHolter2', component: _import('personal_module/records/History/HistoryEightHolter2'), name: 'HistoryEightHolter2', meta: { title: '页面', isTab: false, showfater: false } },
        { path: 'EightHolterReport', component: _import('personal_module/records/EightHolterReport'), name: 'EightHolterReport', meta: { title: '八导联动态报告', isTab: false, showfater: false } }]
      },
      {
        path: 'HistoryTwelve', component: _import('personal_module/records/History/HistoryTwelve'), name: 'HistoryTwelve', meta: { title: '十二导联心电检测记录', isTab: true, showfater: true },
        children: [{ path: 'HistoryTwelve2', component: _import('personal_module/records/History/HistoryTwelve2'), name: 'HistoryTwelve2', meta: { title: '页面', isTab: false, showfater: false } }]
      },
      { path: 'HistoryBloodFat', component: _import('personal_module/records/History/HistoryBloodFat'), name: 'HistoryBloodFat', meta: { title: '血脂检测记录', isTab: true } },
      { path: 'HistoryBloodGlucose', component: _import('personal_module/records/History/HistoryBloodGlucose'), name: 'HistoryBloodGlucose', meta: { title: '血糖检测记录', isTab: true } },
      { path: 'HistoryBloodOxygen', component: _import('personal_module/records/History/HistoryBloodOxygen'), name: 'HistoryBloodOxygen', meta: { title: '血氧检测记录', isTab: true } },
      { path: 'HistoryBloodPressure', component: _import('personal_module/records/History/HistoryBloodPressure'), name: 'HistoryBloodPressure', meta: { title: '血压检测记录', isTab: true } },
      { path: 'HistoryBaby', component: _import('personal_module/records/History/HistoryBaby'), name: 'HistoryBaby', meta: { title: '胎心仪检测记录', isTab: true } },
      { path: 'HistoryBaby2', component: _import('personal_module/records/History/HistoryBaby2'), name: 'HistoryBaby2', meta: { title: '胎心仪检测内容', isTab: true } },
      { path: 'HistoryBio', component: _import('personal_module/records/History/HistoryBio'), name: 'HistoryBio', meta: { title: '生化分析仪检测记录', isTab: true } },
      { path: 'HistoryTem', component: _import('personal_module/records/History/HistoryTem'), name: 'HistoryTem', meta: { title: '体温检测记录', isTab: true } },
      { path: 'HistoryBodyFat', component: _import('personal_module/records/History/HistoryBodyFat'), name: 'HistoryBodyFat', meta: { title: '体脂检测记录', isTab: true } },
      { path: 'HistoryThreeInOne', component: _import('personal_module/records/History/HistoryThreeInOne'), name: 'HistoryThreeInOne', meta: { title: '三合一检测记录', isTab: true } },
      { path: 'HistoryUrine', component: _import('personal_module/records/History/HistoryUrine'), name: 'HistoryUrine', meta: { title: '尿液检测记录', isTab: true } },
      { path: 'HistoryTwoInOne', component: _import('personal_module/records/History/HistoryTwoInOne'), name: 'HistoryTwoInOne', meta: { title: '二合一检测记录', isTab: true } },
      { path: 'HistoryTwoInOne2', component: _import('personal_module/records/History/HistoryTwoInOne2'), name: 'HistoryTwoInOne2', meta: { title: '二合一检测内容', isTab: true },
      children: [{ path: 'HistorySingoHolter2', component: _import('personal_module/records/History/HistorySingoHolter2'), name: 'HistorySingoHolter2', meta: { title: '页面', isTab: false, showfater: false } }] },

      { path: 'Tendency_singo', component: _import('personal_module/records/Tendency/Tendency_singo'), name: 'Tendency_singo', meta: { title: '单导联心电趋势分析', isTab: true } },
      { path: 'Tendency_singoHolter', component: _import('personal_module/records/Tendency/Tendency_singoHolter'), name: 'Tendency_singoHolter', meta: { title: '单导联Holter趋势分析', isTab: true } },

      { path: 'ExamReport', component: _import('personal_module/records/report'), name: 'ExamReport', meta: { title: '综合体检报告', isTab: true } },
      { path: 'ECG_consult', component: _import('personal_module/consultant/ECG_consult'), name: 'ECG_consult', meta: { title: '心电咨询', isTab: true } },
      { path: 'RemoteDiagnosis', component: _import('personal_module/consultant/remote_diagnosis'), name: 'RemoteDiagnosis', meta: { title: '远程诊疗', isTab: true } },
      { path: 'PsInfo', component: _import('personal_module/ps_info/ps_information'), name: 'PsInfo', meta: { title: '个人信息表', isTab: true } },
      { path: 'RelativeManage', component: _import('personal_module/relative/relatives_manage'), name: 'RelativeManage', meta: { title: '亲友管理', isTab: true } },
      { path: 'AccountSetting', component: _import('personal_module/setting/account_setting'), name: 'AccountSetting', meta: { title: '账户设置', isTab: true } },
      { path: 'Appointment', component: _import('personal_module/remote/appointment'), name: 'Appointment', meta: { title: '诊疗预约', isTab: true } },
      { path: 'RemoteHistory', component: _import('personal_module/remote/remoteHistory'), name: 'RemoteHistory', meta: { title: '诊疗记录', isTab: true } },
      { path: 'emergency3', component: _import('personal_module/remote/emergency_treatment3'), name: 'emergency3', meta: { title: '急诊', isTab: true } },
      { path: 'reportUpload', component: _import('personal_module/remote/reportUpload'), name: 'reportUpload', meta: { title: '报告上传', isTab: true } },
      { path: 'ViewTreatReport', component: _import('personal_module/remote/ViewTreatReport'), name: 'ViewTreatReport', meta: { title: '诊疗报告', isTab: true } },
      // { path: '/theme', component: _import('common/theme'), name: 'theme', meta: { title: '主题' } },
    ],
  }
]
