import {storeMaker} from '../../utils/storeMaker.js'

export default {
  namespaced: true,
  state: storeMaker({
    moduleName: '',
    crossPlatform: false,
    fromMainHome: false,
  }),
  mutations: {
    updateModuleName (state, moduleName) {
      state.moduleName = moduleName
    },
    updateMainHome (state, fromMainHome) {
      state.fromMainHome = fromMainHome
    },
    updateCrossPlatform (state, crossPlatform) {
      state.crossPlatform = crossPlatform
    },
  }
}
