import {storeMaker} from '../utils/storeMaker.js'

export default {
    namespaced: true,
    state: storeMaker({
        // 这里写变量名
        // moduleName: '',
        docid:'',
        doctorName:'',
    }),
    mutations: {
        // 这里写变量更新方法，仿照写
        // updateModuleName (state, moduleName) {
        //     state.moduleName = moduleName
        // },
        updateDocId (state, docid) {
            state.docid = docid
        },
        updateDocName (state, doctorName) {
            state.doctorName = doctorName
        },
    }
}