
// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
// const _import = require('./import-' + process.env.NODE_ENV)

const _import = require('./import-production')

export default[
    {
        path: '/remoteTreat',
        component: _import('main'),
        name: 'remoteTreat',
        /*redirect: { name: 'home' },*/
        meta: { title: '主入口整体布局',isTab: false },
        children: [
            // 通过meta对象设置路由展示方式
            // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
            // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
            // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
            /*{ path: 'home', component: _import('common/home'), name: 'home', meta: { title: '首页',isTab: false } },*/
            /*{path: 'page4', component: _import('doctor_station/report/SingoReport1'), name: 'SingoReport1', meta: {title: '静态单导', isTab: true}},*/
            // { path: '/theme', component: _import('common/theme'), name: 'theme', meta: { title: '主题' } },
            /*{path: 'page0', component: _import('remote_treatment/remote_treatment_doc'),name: 'docTreat',meta: {title: '远程诊疗'}}*/
        ],
    }
]