const _import = require('./import-production')

export default[
    {path: '/acrossWithFrame', 
    component: _import('main'),
    children: [
        {
           path: 'HistorySingoJump', component: _import('personal_module/records/History/HistorySingo'), name: 'HistorySingoJump', meta: {title: '单导联心电检测记录', isTab: true, showfater: true},
           children:[{path: 'HistorySingo2Jump', component: _import('personal_module/records/History/HistorySingo2'), name: 'HistorySingo2Jump', meta: {title: '页面', isTab: false, showfater: false}},
            ]
        },
        {
            path: 'HistorySingoHolterJump', component: _import('personal_module/records/History/HistorySingoHolter'), name: 'HistorySingoHolterJump', meta: {title: '单导联Holter检测记录', isTab: true, showfater: true},
            children:[{path: 'HistorySingoHolter2Jump', component: _import('personal_module/records/History/HistorySingoHolter2'), name: 'HistorySingoHolter2Jump', meta: {title: '页面', isTab: false, showfater: false}},
            {path: 'holterReportJump', component: _import('personal_module/records/holter_report'), name: 'holterReportJump', meta: {title: '动态报告', isTab: false, showfater: false}}]
        },
        { path: 'HistoryBloodFatJump', component: _import('personal_module/records/History/HistoryBloodFat'), name: 'HistoryBloodFatJump', meta: { title: '血脂检测记录', isTab: true } },
        { path: 'HistoryBloodGlucoseJump', component: _import('personal_module/records/History/HistoryBloodGlucose'), name: 'HistoryBloodGlucoseJump', meta: { title: '血糖检测记录', isTab: true } },
        { path: 'HistoryBloodOxygenJump', component: _import('personal_module/records/History/HistoryBloodOxygen'), name: 'HistoryBloodOxygenJump', meta: { title: '血氧检测记录', isTab: true } },
        { path: 'HistoryBloodSugarJump', component: _import('personal_module/records/History/HistoryBloodGlucose'), name: 'HistoryBloodSugarJump', meta: { title: '血糖检测记录', isTab: true } },
        { path: 'HistoryBodyTemprature', component: _import('personal_module/records/History/HistoryTem'), name: 'HistoryBodyTemprature', meta: { title: '体温检测记录', isTab: true } },
        { path: 'HistoryThreeInOne', component: _import('personal_module/records/History/HistoryThreeInOne'), name: 'HistoryThreeInOne', meta: { title: '三合一检测记录', isTab: true } },
        { path: 'HistoryBloodPressureJump', component: _import('personal_module/records/History/HistoryBloodPressure'), name: 'HistoryBloodPressureJump', meta: { title: '血压检测记录', isTab: true } },
        { path: 'HistoryBabyJump', component: _import('personal_module/records/History/HistoryBaby'), name: 'HistoryBabyJump', meta: { title: '胎心仪检测记录', isTab: true } },
        { path: 'HistoryBaby2Jump', component: _import('personal_module/records/History/HistoryBaby2'), name: 'HistoryBaby2Jump', meta: { title: '胎心仪检测内容', isTab: true } },
        { path: 'HistoryBioJump', component: _import('personal_module/records/History/HistoryBio'), name: 'HistoryBioJump', meta: { title: '生化分析仪检测记录', isTab: true } },
        { path: 'HistoryTemJump', component: _import('personal_module/records/History/HistoryTem'), name: 'HistoryTemJump', meta: { title: '体温检测记录', isTab: true } },
        { path: 'HistoryBodyFatJump', component: _import('personal_module/records/History/HistoryBodyFat'), name: 'HistoryBodyFatJump', meta: { title: '体脂检测记录', isTab: true } },
        { path: 'HistoryThreeInOneJump', component: _import('personal_module/records/History/HistoryThreeInOne'), name: 'HistoryThreeInOneJump', meta: { title: '三合一检测记录', isTab: true } },
        { path: 'HistoryUrineJump', component: _import('personal_module/records/History/HistoryUrine'), name: 'HistoryUrineJump', meta: { title: '尿液检测记录', isTab: true } },
        {
            path: 'HistoryEightJump', component: _import('personal_module/records/History/HistoryEight'), name: 'HistoryEightJump', meta: {title: '八导联心电检测记录', isTab: true, showfater: true},
            children:[{path: 'HistoryEight2Jump', component: _import('personal_module/records/History/HistoryEight2'), name: 'HistoryEight2Jump', meta: {title: '页面', isTab: false, showfater: false}}]
        },
        {
            path: 'HistoryEightHolterJump', component: _import('personal_module/records/History/HistoryEightHolter'), name: 'HistoryEightHolterJump', meta: {title: '八导联Holter检测记录', isTab: true, showfater: true},
            children:[{path: 'HistoryEightHolter2Jump', component: _import('personal_module/records/History/HistoryEightHolter2'), name: 'HistoryEightHolter2Jump', meta: {title: '页面', isTab: false, showfater: false}}]
        },
    ]
},
]